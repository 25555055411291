.wrapper {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 100px 1fr 100px;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 70px;
  }
}
