@import 'styles/theme';

@mixin button {
  padding: 8px 35px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
}

.normal {
  @include button;

  background-color: $white;
  color: $black;
  border: none;
}

.ghost {
  @include button;

  background: none;
  color: $white;
  border: 2px solid $white;
}
