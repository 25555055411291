@import 'styles/theme';

.wrapper {
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link {
  color: $white;
  transition: color 0.5s;

  &:hover {
    color: lightblue;
  }
}

.paragraph {
  font-weight: 600;
  text-align: center;
}

.author {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-left: 10px;
  margin-top: 5px;
}

@media (min-width: 800px) {
  .wrapper {
    flex-direction: row;
    padding: 30px 60px;
    justify-content: space-between;
  }
}
