.wrapper {
  background-color: rgba(0, 0, 0, 30%);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #0f0f0f;
  padding: 30px;
  border-radius: 5px;
}

.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close {
  cursor: pointer;
  width: 26px;
}

.title {
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 800px) {
  .modal {
    width: 650px;
    height: auto;
  }
}
